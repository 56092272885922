import { memo, PropsWithChildren } from 'react'
import { Box, Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'

export type IntentionSettingDialogHeaderContainerProps = PropsWithChildren<{}>

function IntentionSettingDialogHeaderContainer(
    props: IntentionSettingDialogHeaderContainerProps
) {
    const { children } = props
    const { spacing, breakpoints, Logo } = useTheme()
    const isTabletView = useMediaQuery(breakpoints.down('md'))
    const navHeight = 'var(--navbar-height)'
    const rowHeightSmall = 'var(--navbar-row-height-small)'

    return (
        <Stack
            sx={{
                my: isTabletView ? spacing(2.5) : undefined,
                px: isTabletView ? spacing(2) : spacing(5),
                minHeight: 'unset !important',
                height: isTabletView ? rowHeightSmall : navHeight,
                maxHeight: isTabletView ? rowHeightSmall : navHeight
            }}
            data-testid="intention-setting-dialog-header-container"
        >
            <Stack
                direction="row"
                sx={{
                    height: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Stack>
                    <Box minHeight={48} minWidth={42}>
                        <Logo />
                    </Box>
                </Stack>
                <Stack>{children}</Stack>
            </Stack>
        </Stack>
    )
}

export default memo(IntentionSettingDialogHeaderContainer)
