import {
    Button,
    type ButtonProps,
    Menu,
    MenuItem,
    type MenuProps
} from '@mui/material'
import {
    CoreTypography,
    type CoreTypographyProps,
    LeafIcon
} from '@thriveglobal/thrive-web-leafkit'
import {
    type MouseEvent,
    memo,
    useCallback,
    useMemo,
    useState,
    useId
} from 'react'

export type ButtonMenuOption<T extends string | number = string> = {
    label: string
    value: T
}

export interface ButtonMenuProps<T extends string | number = string> {
    value?: T
    options: ButtonMenuOption<T>[]
    variant?: ButtonProps['variant']
    color?: ButtonProps['color']
    onSelect: (option: ButtonMenuOption<T>) => void
    startIcon?: React.ReactNode
    endIcon?: React.ReactNode
    fullWidth?: boolean
    buttonTextVariant?: CoreTypographyProps['variant']
    label: string
}

const ButtonMenu = ({
    value,
    options,
    variant,
    color,
    onSelect,
    startIcon,
    endIcon,
    fullWidth,
    buttonTextVariant,
    label
}: ButtonMenuProps) => {
    const uniqueId = useId()
    const { expandButtonId, menuId } = useMemo(
        () => ({
            expandButtonId: 'button-menu-expand-' + uniqueId,
            menuId: 'button-menu-' + uniqueId
        }),
        [uniqueId]
    )

    const [selectedOption, setSelectedOption] = useState(
        () => options.find((o) => o.value === value) ?? options[0]
    )
    const [anchorEl, setAnchorEl] = useState<MenuProps['anchorEl']>(undefined)

    const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }, [])

    const handleSelectOption = useCallback(
        (option: ButtonMenuOption) => () => {
            setSelectedOption(option)
            onSelect(option)
            setAnchorEl(undefined)
        },
        [onSelect]
    )

    const handleCloseMenu = useCallback(() => setAnchorEl(undefined), [])
    const isOpen = Boolean(anchorEl)

    return (
        <>
            <Button
                id={expandButtonId}
                aria-label={label}
                variant={variant ?? 'contained'}
                color={color ?? 'secondary'}
                startIcon={startIcon}
                endIcon={
                    endIcon ?? (
                        <LeafIcon
                            icon="chevron-down"
                            iconStyle="solid"
                            fontSize="small"
                        />
                    )
                }
                aria-controls={isOpen ? menuId : undefined}
                aria-expanded={isOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
                fullWidth={fullWidth}
            >
                <CoreTypography
                    variant={buttonTextVariant}
                    customVariant={!buttonTextVariant ? 'buttonSmall' : null}
                >
                    {selectedOption.label}
                </CoreTypography>
            </Button>
            <Menu
                id={menuId}
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleCloseMenu}
                keepMounted
                MenuListProps={{
                    'aria-labelledby': expandButtonId
                }}
            >
                {options.map((o, i) => (
                    <MenuItem
                        key={`${o.label}-${o.value}`}
                        onClick={handleSelectOption(o)}
                    >
                        {o.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default memo(ButtonMenu)
