import { LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { useCallback } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import ButtonMenu, {
    ButtonMenuOption
} from '../../elements/ButtonMenu/ButtonMenu'

const messages = defineMessages({
    localeSelectorAriaLabel: {
        defaultMessage: 'Choose your language',
        description: 'Aria label for language selector'
    }
})

const AVAILABLE_LOCALES = [
    { value: 'en-US', label: 'English' },
    { value: 'es-419', label: 'Español' },
    { value: 'fr-CA', label: 'Français (Canada)' },
    { value: 'fr-FR', label: 'Français (France)' },
    { value: 'it-IT', label: 'Italiano' },
    { value: 'de-DE', label: 'Deutsch' },
    { value: 'pt-BR', label: 'Português' },
    { value: 'ja-JP', label: '日本語' },
    { value: 'zh-Hans', label: '简体中文' }
]

export type LocaleSelectorProps = {
    locale?: string
    onSelect: (timezone: string) => void
}

const LocaleSelector = ({ locale, onSelect }: LocaleSelectorProps) => {
    const { formatMessage } = useIntl()
    const handleSelect = useCallback(
        (option: ButtonMenuOption) => {
            onSelect(option.value)
        },
        [onSelect]
    )
    return (
        <ButtonMenu
            label={formatMessage(messages.localeSelectorAriaLabel)}
            variant="text"
            color="primary"
            buttonTextVariant="body1"
            fullWidth
            options={AVAILABLE_LOCALES}
            value={locale}
            onSelect={handleSelect}
            startIcon={<LeafIcon icon="globe" fontSize="small" />}
        />
    )
}

export default LocaleSelector
