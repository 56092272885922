export const REFERRER_NAME = 'journeys'

export const FALLBACK_CARD_IMAGE =
    'https://assets.thriveglobal.com/journeys/journey_onboarding_placeholder.png'

export const FALLBACK_FEATURED_CARD_IMAGE =
    'https://assets.thriveglobal.com/library/decorative_images/library_walking_on_the_beach.jpg'

export const MICROSTEP_CHECKIN_DATE_FORMAT = 'YYYY-MM-DD'

export const COMPANY_RESOURCES_PAGE_SIZE = 3

export * from './featureFlags'

//mock images for the new daily experience
export const MOCK_IMAGE_ONE =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/563ea223-eb9a-4735-d384-c9d19100ea00/cms'
export const MOCK_IMAGE_THREE =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/a9c84a85-58fc-4383-7134-94019dc0b600/cms'
export const MOCK_IMAGE_FOUR =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/593329da-1bf3-43d0-60ad-842668f0ad00/cms'

export const NUMBER_OF_RECOMMENDATIONS = 3
