import { type CombinedState, type EnhancedStore } from '@reduxjs/toolkit'
import { type TypedUseSelectorHook } from 'react-redux'
import {
    type SettingsState,
    type UserState,
    store as coreStore,
    useAppDispatch as useCoreDispatch,
    useAppSelector as useCoreSelector
} from '@thriveglobal/thrive-web-core'
import {
    microstepsSliceName,
    type MicrostepsSliceActions,
    type MicrostepsState
} from './microsteps'
import { settingsSliceName } from './settings'
import { userSliceName } from './user'

export type AppState = CombinedState<{
    [settingsSliceName]: SettingsState
    [userSliceName]: UserState
    [microstepsSliceName]: MicrostepsState
}>

type CoreStore = typeof coreStore

export type AppStore = EnhancedStore<AppState, MicrostepsSliceActions> &
    CoreStore

export const useAppSelector =
    useCoreSelector as unknown as TypedUseSelectorHook<AppState>

export const useAppDispatch = useCoreDispatch as () => AppStore['dispatch']
