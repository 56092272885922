import { Box, Divider, Stack, useMediaQuery } from '@mui/material'
import {
    captureException,
    getBestFitLocale,
    HybridEventType,
    isRunningInWebview,
    postMessageToWebview,
    updateUserLocaleTimezone,
    useAppSelector,
    type WebApplicationLocale
} from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    LeafExternalLink,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { debounce } from 'lodash'
import { memo, useCallback, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import LocaleSelector from './LocaleSelector'
import TimezoneSelector from './TimezoneSelector'
import {
    useTrackLanguageUpdated,
    useTrackSupportButtonClick,
    useTrackTimezoneUpdated
} from '../../../tracking/onboarding'

const messages = defineMessages({
    support: {
        defaultMessage: 'Support',
        description: 'Text for button that links the user to the support page'
    }
})

const CustomDivider = () => {
    const { palette } = useTheme()
    return (
        <Divider
            role="presentation"
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{
                borderWidth: 1,
                borderColor: palette.text.secondary,
                marginY: 0.5
            }}
        />
    )
}

function IntentionSettingDialogOnboardingHeader() {
    const { formatMessage } = useIntl()
    const { breakpoints } = useTheme()
    const isMobileView = useMediaQuery(breakpoints.down('sm'))
    const settings = useAppSelector((state) => state.settings)

    const trackTimezoneUpdated = useTrackTimezoneUpdated()
    const trackLanguageUpdated = useTrackLanguageUpdated()

    const [locale, setLocale] = useState<string | null>(
        () => settings?.locale || getBestFitLocale()
    )
    const [timezone, setTimezone] = useState<string | null>(settings?.timezone)

    const onSave = useMemo(() => {
        const save = async (
            newLocale: WebApplicationLocale,
            newTimezone: string
        ) => {
            try {
                await updateUserLocaleTimezone(newLocale, newTimezone)

                //reload page when locale is changed to get the updated strings
                if (locale !== newLocale) {
                    if (isRunningInWebview()) {
                        postMessageToWebview({
                            type: HybridEventType.UPDATE_LOCALE,
                            value: locale
                        })
                    }

                    window.location.reload()
                }
            } catch (error) {
                captureException(error)
            }
        }
        return debounce(save, 500)
    }, [locale])

    const handleLocaleChange = useCallback(
        async (value: string) => {
            trackLanguageUpdated()
            await onSave(value, timezone || '')
            setLocale(value)
        },
        [trackLanguageUpdated, onSave, timezone]
    )

    const handleTimezoneChange = useCallback(
        async (value: string) => {
            await onSave(locale || '', value)
            trackTimezoneUpdated()
            setTimezone(value)
        },
        [locale, onSave, trackTimezoneUpdated]
    )

    const trackSupportClick = useTrackSupportButtonClick()
    const handleSupportClick = useCallback(() => {
        trackSupportClick()
    }, [trackSupportClick])

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            width="fit-content"
            gap={1}
            data-testid="intention-setting-dialog-onboarding-header"
        >
            {!isMobileView && (
                <LeafExternalLink
                    display="inline-flex"
                    marginRight={1}
                    underline="none"
                    externalLink="mailto:support@thriveglobal.com"
                    onClick={handleSupportClick}
                    tabIndex={0}
                    hideIcon
                >
                    <CoreTypography>
                        {formatMessage(messages.support)}
                    </CoreTypography>
                </LeafExternalLink>
            )}
            {!isMobileView && <CustomDivider />}
            <Box minWidth={120}>
                <LocaleSelector locale={locale} onSelect={handleLocaleChange} />
            </Box>
            {!isMobileView && <CustomDivider />}
            {!isMobileView && (
                <Box minWidth={120}>
                    <TimezoneSelector
                        timezone={timezone}
                        onSelect={handleTimezoneChange}
                    />
                </Box>
            )}
        </Stack>
    )
}

export default memo(IntentionSettingDialogOnboardingHeader)
