import { defaultTrackingProps } from '@thriveglobal/thrive-web-tracking'
import type {
    CategorySelectedProperties,
    ContentCompletedProperties,
    ContentSelectedProperties,
    ContentStartedProperties,
    FilterSelectedProperties,
    FocusAreasSetProperties,
    FocusAreasSuggestedProperties,
    IntentionSelectedProperties,
    JourneyLevelCompletedProperties,
    JourneyStartedProperties,
    ListSortedProperties,
    MicrostepAddedProperties,
    MicrostepCheckinCompletedProperties,
    MicrostepCheckinRemovedProperties,
    MicrostepServedProperties,
    OnboardingCompletedProperties,
    PromptSelectedProperties,
    ReactionSubmittedProperties,
    ResourceSelectedProperties,
    ResourceServedProperties,
    SurveyServedProperties,
    SurveySubmittedProperties
} from '@thriveglobal/thrive-web-tracking/dist/Avo'

export function createDefaultTrackingProps<TProps>(
    props: TProps
): TProps & typeof defaultTrackingProps {
    return { ...defaultTrackingProps, ...props }
}

export const resourceServedDefaultProps =
    createDefaultTrackingProps<ResourceServedProperties>({
        activityType: null,
        featureType: null,
        firstJourney: null,
        journey: null,
        journeyDay: null,
        journeyId: null,
        journeyLevel: null,
        appUserId: null,
        contentFormatType: null,
        contentId: null,
        contentSource: null,
        contentSubtype: null,
        contentTitle: null,
        contentType: null,
        contentUrl: null,
        nudgeType: null,
        challengeId: '',
        challengeTheme: '',
        challengeType: '',
        dayNumber: 0,
        isSystemEvent: false,
        teamId: '',
        teamType: undefined
    })

export const resourceSelectedDefaultProps =
    createDefaultTrackingProps<ResourceSelectedProperties>({
        contentFormatType: null,
        contentId: null,
        contentSource: null,
        contentSubtype: null,
        contentType: null,
        contentUrl: null,
        firstJourney: null,
        isOnboarding: null,
        activityType: null,
        contentTitle: null,
        featureType: null,
        journey: null,
        journeyDay: null,
        journeyId: null,
        journeyLevel: null,
        appUserId: null,
        challengeId: null,
        challengeTheme: null,
        challengeType: null,
        dayNumber: null,
        displayText: null,
        nudgeType: null,
        tabName: null,
        teamId: null,
        teamType: null,
        isSystemEvent: false
    })

export const journeyStartedDefaultProps =
    createDefaultTrackingProps<JourneyStartedProperties>({
        firstJourney: null,
        tabName: null,
        userId_: null,
        isAutoEnrolled: false,
        isOnboarding: false,
        activityType: null,
        featureType: null,
        journey: null,
        journeyDay: null,
        journeyId: null,
        journeyLevel: null,
        isSystemEvent: false
    })

export const defaultReactionSubmittedProps =
    createDefaultTrackingProps<ReactionSubmittedProperties>({
        contentFormatType: null,
        contentSource: null,
        contentSubtype: null,
        contentTitle: null,
        contentType: null,
        contentUrl: null,
        conversationId: null,
        feedbackAnswer: null,
        feedbackQuestion: null,
        messageType: null,
        openAnswer: null,
        openQuestion: null,
        resetType: null,
        tabName: null,
        transactionId: null,
        activityType: null,
        contentId: null,
        featureType: null,
        isSystemEvent: false
    })

export const defaultPromptSelectedProps =
    createDefaultTrackingProps<PromptSelectedProperties>({
        contentFormatType: null,
        contentId: null,
        contentSource: null,
        contentSubtype: null,
        contentTitle: null,
        contentType: null,
        contentUrl: null,
        displayText: null,
        resetId: null,
        resetName: null,
        userId_: null,
        isOnboarding: false,
        tabName: null,
        tstPlatform: null,
        activityType: null,
        featureType: null,
        checkInDate: null,
        isAutomaticCheckin: false,
        microstepBody: null,
        microstepId: null,
        microstepName: null,
        notificationCount: null,
        nudgeType: null,
        isSystemEvent: false
    })

export const defaultSurveySubmittedProps =
    createDefaultTrackingProps<SurveySubmittedProperties>({
        assessmentId: null,
        assessmentItemId: null,
        assessmentName: null,
        assessmentVariant: null,
        dciCount: null,
        isOnboarding: false,
        npsScore: null,
        openAnswer: null,
        responseText: null,
        responseValue: null,
        firstJourney: null,
        tabName: null,
        activityType: null,
        assessmentAttemptId: null,
        featureType: null,
        journey: null,
        journeyDay: null,
        journeyId: null,
        journeyLevel: null,
        surveyLocation: null,
        messageQueueId: null,
        dispatchedDate: null,
        isSystemEvent: false
    })

export const defaultSurveyServedProps =
    createDefaultTrackingProps<SurveyServedProperties>({
        assessmentAttemptId: null,
        assessmentId: null,
        assessmentName: null,
        assessmentVariant: null,
        isOnboarding: false,
        userId_: null,
        activityType: null,
        featureType: null,
        surveyLocation: null,
        dispatchedDate: null,
        isSystemEvent: false,
        onboardingAttemptNumber: 0
    })

export const defaultMicrostepCheckinCompletedProps =
    createDefaultTrackingProps<MicrostepCheckinCompletedProperties>({
        checkInDate: null,
        challengeId: null,
        challengeTheme: null,
        challengeType: null,
        isAutomaticCheckin: false,
        isOnboarding: false,
        teamId: null,
        teamType: null,
        firstJourney: null,
        tabName: null,
        activityType: null,
        dayNumber: null,
        featureType: null,
        journey: null,
        journeyDay: null,
        journeyId: null,
        journeyLevel: null,
        microstepBody: null,
        microstepId: null,
        microstepName: null,
        pageSection: null,
        isSystemEvent: false
    })

export const defaultMicrostepCheckingRemovedProps =
    createDefaultTrackingProps<MicrostepCheckinRemovedProperties>({
        checkInDate: null,
        activityType: null,
        featureType: null,
        isAutomaticCheckin: false,
        microstepBody: null,
        microstepId: null,
        microstepName: null,
        isSystemEvent: false
    })

export const defaultContentCompletedProps =
    createDefaultTrackingProps<ContentCompletedProperties>({
        activityType: null,
        challengeId: null,
        challengeTheme: null,
        challengeType: null,
        contentFormatType: null,
        contentId: null,
        contentSource: null,
        contentSubtype: null,
        contentTitle: null,
        contentType: null,
        contentUrl: null,
        conversationId: null,
        dayNumber: null,
        featureType: null,
        messageType: null,
        resetType: null,
        teamId: null,
        teamType: null,
        transactionId: null,
        tstPlatform: null,
        isSystemEvent: false
    })

export const defaultContentStartedProps =
    createDefaultTrackingProps<ContentStartedProperties>({
        activityType: null,
        challengeId: null,
        challengeTheme: null,
        challengeType: null,
        contentFormatType: null,
        contentId: null,
        contentSource: null,
        contentSubtype: null,
        contentTitle: null,
        contentType: null,
        contentUrl: null,
        conversationId: null,
        dayNumber: null,
        featureType: null,
        messageType: null,
        resetType: null,
        tabName: null,
        teamId: null,
        teamType: null,
        transactionId: null,
        tstPlatform: null,
        isSystemEvent: false
    })

export const defaultContentSelectedProps =
    createDefaultTrackingProps<ContentSelectedProperties>({
        activityType: null,
        contentFormatType: null,
        contentId: null,
        contentSource: null,
        contentSubtype: null,
        contentTitle: null,
        contentType: null,
        contentUrl: null,
        featureType: null,
        isSystemEvent: false
    })

export const defaultJourneyLevelCompleted =
    createDefaultTrackingProps<JourneyLevelCompletedProperties>({
        activityType: null,
        featureType: null,
        firstJourney: null,
        journey: null,
        journeyDay: null,
        journeyId: null,
        journeyLevel: null,
        isSystemEvent: false
    })

export const defaultMicrostepServedProps =
    createDefaultTrackingProps<MicrostepServedProperties>({
        activityType: null,
        checkInDate: null,
        featureType: null,
        isAutomaticCheckin: null,
        microstepBody: null,
        microstepId: null,
        microstepName: null,
        isSystemEvent: false
    })

export const defaultCategorySelectedProps =
    createDefaultTrackingProps<CategorySelectedProperties>({
        activityType: null,
        featureType: null,
        displayText: null,
        isSystemEvent: false
    })

export const defaultFilterSelectedProps =
    createDefaultTrackingProps<FilterSelectedProperties>({
        activityType: null,
        featureType: null,
        displayText: null,
        isSystemEvent: false,
        filtersApplied: null
    })

export const defaultListSortedProps =
    createDefaultTrackingProps<ListSortedProperties>({
        activityType: null,
        featureType: null,
        displayText: null,
        isSystemEvent: false
    })

export const defaultMicrostepAddedProps =
    createDefaultTrackingProps<MicrostepAddedProperties>({
        activityType: null,
        featureType: null,
        microstepId: null,
        tabName: null,
        isSystemEvent: false
    })

export const defaultIntentionSelectedProps =
    createDefaultTrackingProps<IntentionSelectedProperties>({
        activityType: null,
        featureType: null,
        responseText: null,
        isOnboarding: false,
        isSystemEvent: false
    })

export const defaultFocusAreasServedSuggestedProps =
    createDefaultTrackingProps<FocusAreasSuggestedProperties>({
        activityType: null,
        featureType: null,
        focusAreas: null,
        isSystemEvent: null,
        isOnboarding: false
    })

export const defaultOnboardingCompletedProps =
    createDefaultTrackingProps<OnboardingCompletedProperties>({
        activityType: undefined,
        featureType: undefined,
        firstJourney: false,
        isOnboarding: false,
        journey: '',
        journeyDay: 0,
        journeyId: '',
        journeyLevel: 0,
        isSystemEvent: false,
        onboardingAttemptNumber: 0
    })

export const defaultFocusAreasSetProps =
    createDefaultTrackingProps<FocusAreasSetProperties>({
        activityType: null,
        companyId: null,
        featureType: null,
        focusAreas: null,
        isLoggedIn: null,
        license: null,
        previousPath: null,
        subscriptionId: null,
        subscriptionType: null,
        isOnboarding: false,
        isSystemEvent: false,
        responseText: ''
    })
