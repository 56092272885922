import {
    ComponentType,
    lazy,
    type LazyExoticComponent,
    Suspense,
    type ComponentProps
} from 'react'
import ErrorBoundaryWrapper from '../components/fallback/ErrorBoundaryWrapper'

/**
 * Wrap a component in a Suspense component with a null fallback.
 * We do this in order to avoid loading all components at once.
 * Downstream apps may only need one or two components from Jorneys, and this ensures that only the necessary components are loaded.
 * @param Component - The component to wrap.
 * @returns The wrapped component.
 */
function wrapComponentInSuspense<T extends ComponentType<any>>(
    boundaryName: string,
    Component: LazyExoticComponent<T>
) {
    return (props: ComponentProps<T>): JSX.Element => (
        <ErrorBoundaryWrapper
            boundaryName={boundaryName}
            boundaryType="Feature"
            fallbackComponent={null}
        >
            <Suspense fallback={null}>
                <Component {...props} />
            </Suspense>
        </ErrorBoundaryWrapper>
    )
}

export const Microstep = wrapComponentInSuspense(
    'Microstep',
    lazy(() => import('../pages/dailyExperience/features/microsteps/Microstep'))
)

export const PersonalizedRecommendedContent = wrapComponentInSuspense(
    'PersonalizedRecommendedContent',
    lazy(
        () =>
            import(
                '../components/features/Intention/PersonalizedRecommendedContent/PersonalizedRecommendedContent'
            )
    )
)

export { default as IntentionSettingDialog } from '../components/features/IntentionSetting/IntentionSettingDialog'
