import { userSlice, type UserState } from '@thriveglobal/thrive-web-core'
import { type CombinedState } from 'redux'
import { createSelector } from '@reduxjs/toolkit'

export const userSliceName = userSlice.name

export type RootStateWithUserState = CombinedState<{
    [userSliceName]: UserState
}>

export const selectUserState = (
    rootState: RootStateWithUserState
): UserState => {
    return rootState[userSliceName]
}

export const selectUserId = createSelector(
    selectUserState,
    (userState) => userState.userId
)

export const selectUserFullName = createSelector(
    selectUserState,
    (userState) => userState.fullName
)
