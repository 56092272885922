import { Box, Stack } from '@mui/material'
import { FallbackProps } from 'react-error-boundary'
import Reload from '../icons/Reload'

/**
 * This Fallback component MUST NOT USE ANY COMPONETS FROM ANOTHER PACAKGE INCLUDING LEAFKIT
 * Once the Single Spa Fallback is triggered not external components from a thrive library can be used in the fallback
 * Components from @mui/material are still fine
 */

export const MFEFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            minHeight={'100vh'}
            width={'100%'}
        >
            <Stack>
                <Box>
                    <Stack spacing={2} sx={{ my: 4 }}>
                        <Reload
                            style={{ cursor: 'pointer' }}
                            width={40}
                            height={40}
                            onClick={resetErrorBoundary}
                        />
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}
