import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'
import { ErrorBoundary } from '@thriveglobal/thrive-web-errors'
import { captureException } from '@thriveglobal/thrive-web-core'
import { MFEFallback } from './components/fallback/MFEFallback'

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(err, info, props) {
        captureException(err, { info, props }, 'thrive-web-journeys')
        return (
            <ErrorBoundary
                boundaryName="AppContainer"
                boundaryType={'MicroFrontend'}
                packageName={process.env.APP_NAME}
                packageOwner={process.env.PROJECT_OWNER}
                packageVersion={process.env.APP_VERSION}
                fallbackComponent={MFEFallback}
            />
        )
    }
})

export const { bootstrap, mount, unmount } = lifecycles
export * from './shared'
