import {
    FetchPulseCheckInMutation,
    PulseSurveyQuestionDto
} from '../../graphql/generated/autogenerated'
import { DailyCheckinStatus } from '../../data/pulse/constants'

// Transform survey into full daily checkin structure
export function transformSurveyByIdResponseToPulseCheckinResponse(
    survey: PulseSurveyQuestionDto,
    hasDailyCheckinSubmitted: boolean
): FetchPulseCheckInMutation {
    return {
        pulse: {
            surveys: {
                requestCheckIn: {
                    // fake response if daily checkin is submitted
                    response: hasDailyCheckinSubmitted
                        ? {
                              __typename: 'DailyCheckInResponseDTO',
                              recommendAcuteCare: false,
                              selectedOption: null,
                              recommendedContentDimension: null,
                              responseChannel: null
                          }
                        : null,
                    // fake status if daily checkin is submitted
                    status: hasDailyCheckinSubmitted
                        ? DailyCheckinStatus.PREVIOUSLY_SAVED
                        : null,
                    survey: survey,
                    pulseUserId: null,
                    dispatchDate: null,
                    thriveId: null,
                    recommendedContentDimension: null
                }
            }
        }
    }
}
