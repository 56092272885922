import { useCallback } from 'react'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import {
    resourceSelectedDefaultProps,
    resourceServedDefaultProps
} from './defaultTrackingProps'

export const useTrackAcuteCareViewed = () => {
    return useCallback(
        ({
            journey,
            journeyId,
            journeyLevel,
            journeyDay
        }: {
            journey: string
            journeyId: string
            journeyLevel: number
            journeyDay: number
        }) => {
            Avo.resourceServed({
                ...resourceServedDefaultProps,
                activityType: 'benefits_connect_viewed',
                featureType: 'benefits_connect',
                journey,
                journeyDay,
                journeyId,
                journeyLevel
            })
        },
        []
    )
}

export const useTrackAcuteCareSelected = () => {
    return useCallback(
        ({
            journey,
            journeyDay,
            journeyId,
            journeyLevel,
            careOption
        }: {
            careOption: string
            journey: string
            journeyId: string
            journeyLevel: number
            journeyDay: number
        }) => {
            Avo.resourceSelected({
                ...resourceSelectedDefaultProps,
                activityType: 'benefits_connect_selected',
                featureType: 'benefits_connect',
                journey,
                journeyDay: journeyDay,
                journeyId: journeyId,
                journeyLevel,
                contentTitle: careOption
            })
        },
        []
    )
}
