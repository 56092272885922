import { getPlatform } from '@thriveglobal/thrive-web-core'
import { DailyCheckinStatus, PLATFORM_TO_PULSE_CHANNEL_MAP } from './constants'
import {
    Channel,
    FetchPulseCheckInMutation
} from '../../graphql/generated/autogenerated'
import {
    dailyCheckInStatusSelector,
    hasSurveyRespondedSelector
} from './selectors'

export function getPulseChannel() {
    const appPlatform = getPlatform()

    const pulseChannel =
        PLATFORM_TO_PULSE_CHANNEL_MAP[appPlatform] || Channel.Web

    return pulseChannel
}

export function calculateIsPulseSurveyResponded({
    pulseResponse,
    hideDailyCheckIn = false
}: {
    pulseResponse: FetchPulseCheckInMutation
    hideDailyCheckIn?: boolean
}): boolean {
    if (hideDailyCheckIn) {
        return true
    }

    const dailyCheckinStatus = dailyCheckInStatusSelector(pulseResponse)
    if (dailyCheckinStatus === DailyCheckinStatus.PREVIOUSLY_SAVED) {
        return hasSurveyRespondedSelector(pulseResponse)
    }

    return false
}
