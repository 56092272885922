import { useMemo } from 'react'
import {
    useGetOnboardingProgressQuery,
    UserOnboardingStatus,
    useSetOnboardingProgressMutation
} from '../../graphql/generated/autogenerated'
import { Apollo } from '@thriveglobal/thrive-web-core'

type UseOnboardingStatus = {
    setOnboardingCompleted: () => Promise<void>
} & (
    | {
          loading: false
          status: UserOnboardingStatus
          error: null
      }
    | {
          loading: true
          status: undefined
          error: null
      }
    | {
          loading: false
          status: undefined
          error: Apollo.ApolloError
      }
)

const useOnboardingStatus = (): UseOnboardingStatus => {
    const { data, loading, error } = useGetOnboardingProgressQuery()
    const [setOnboardingProgressMutation] = useSetOnboardingProgressMutation()

    return useMemo(() => {
        const onboardingProgress = data?.onboarding?.getOnboardingProgress

        const setOnboardingCompleted = async () => {
            if (
                !loading &&
                !error &&
                onboardingProgress.status !== UserOnboardingStatus.Completed
            ) {
                await setOnboardingProgressMutation({
                    variables: {
                        status: UserOnboardingStatus.Completed
                    }
                })
            }
        }

        if (loading) {
            return {
                loading: true,
                status: undefined,
                setOnboardingCompleted,
                error: null
            }
        }

        if (error || !data) {
            return {
                loading: false,
                status: undefined,
                setOnboardingCompleted,
                error
            }
        }

        return {
            status: onboardingProgress.status,
            loading: false,
            setOnboardingCompleted,
            error: null
        }
    }, [data, error, loading, setOnboardingProgressMutation])
}

export default useOnboardingStatus
export { UserOnboardingStatus }
