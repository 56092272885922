import { supportedValuesOf } from '@formatjs/intl-enumerator'
import { LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { useCallback, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import ButtonMenu, {
    ButtonMenuOption
} from '../../../components/elements/ButtonMenu/ButtonMenu'

const messages = defineMessages({
    timezoneSelectorAriaLabel: {
        defaultMessage: 'Choose your time zone',
        description: 'Aria label for time zone selector'
    },
    pacificTime: {
        defaultMessage: 'America - Pacific Time',
        description: 'Timezone description'
    },
    mountainTime: {
        defaultMessage: 'America - Mountain Time',
        description: 'Timezone description'
    },
    centralTime: {
        defaultMessage: 'America - Central Time',
        description: 'Timezone description'
    },
    easternTime: {
        defaultMessage: 'America - Eastern Time',
        description: 'Timezone description'
    },
    japanTime: {
        defaultMessage: 'Japan Standard Time',
        description: 'Timezone description'
    },
    indianTime: {
        defaultMessage: 'Indian Standard Time',
        description: 'Timezone description'
    },
    westernEuropeanTime: {
        defaultMessage: 'Western European Time',
        description: 'Timezone description'
    },
    centralEuropeanTime: {
        defaultMessage: 'Central European Time',
        description: 'Timezone description'
    }
})

export type TimezoneSelectorProps = {
    timezone?: string
    onSelect: (timezone: string) => void
}

const TimezoneSelector = ({ timezone, onSelect }: TimezoneSelectorProps) => {
    const { formatMessage } = useIntl()

    const timezoneOptions = supportedValuesOf('timeZone')
    const formattedTimezoneOptions = useMemo(() => {
        const alias = {
            ['America/Los_Angeles']: formatMessage(messages.pacificTime),
            ['America/Denver']: formatMessage(messages.mountainTime),
            ['America/Chicago']: formatMessage(messages.centralTime),
            ['America/New_York']: formatMessage(messages.easternTime),
            ['Asia/Tokyo']: formatMessage(messages.japanTime),
            ['Asia/Calcutta']: formatMessage(messages.indianTime),
            ['Europe/Dublin']: formatMessage(messages.westernEuropeanTime),
            ['Europe/Copenhagen']: formatMessage(messages.centralEuropeanTime)
        }

        const excludingAlias = timezoneOptions.reduce((acc, timezone) => {
            if (!alias[timezone]) {
                acc.push({
                    label: timezone,
                    value: timezone
                })
            }
            return acc
        }, [])

        const mappedAliases = Object.keys(alias).map((key) => ({
            label: alias[key],
            value: key
        }))

        return mappedAliases.concat(excludingAlias)
    }, [timezoneOptions, formatMessage])

    const handleSelect = useCallback(
        (option: ButtonMenuOption) => {
            onSelect(option.value)
        },
        [onSelect]
    )
    return (
        <ButtonMenu
            label={formatMessage(messages.timezoneSelectorAriaLabel)}
            variant="text"
            color="primary"
            buttonTextVariant="body1"
            fullWidth
            options={formattedTimezoneOptions}
            value={timezone}
            onSelect={handleSelect}
            startIcon={<LeafIcon icon="clock" fontSize="small" />}
        />
    )
}

export default TimezoneSelector
