import {
    FetchPulseCheckInMutation,
    PulseSurveyQuestionDto,
    QuestionDto
} from '../../graphql/generated/autogenerated'

export const surveySelector = (
    pulseCheckData: FetchPulseCheckInMutation
): PulseSurveyQuestionDto => {
    return (
        (pulseCheckData?.pulse?.surveys?.requestCheckIn
            ?.survey as PulseSurveyQuestionDto) ?? null
    )
}

export const dispatchDateSelector = (
    pulseCheckData: FetchPulseCheckInMutation
): string => {
    return pulseCheckData?.pulse?.surveys?.requestCheckIn?.dispatchDate ?? null
}

export const recommendAcuteCareSelector = (
    pulseCheckData: FetchPulseCheckInMutation
): boolean => {
    return (
        pulseCheckData?.pulse?.surveys?.requestCheckIn?.response
            ?.recommendAcuteCare ?? false
    )
}

export const surveyQuestionSelector = (
    pulseCheckData: FetchPulseCheckInMutation
): QuestionDto => {
    return (
        pulseCheckData?.pulse?.surveys?.requestCheckIn?.survey?.question ?? null
    )
}

export const hasSurveyRespondedSelector = (
    pulseCheckInData: FetchPulseCheckInMutation
): boolean => {
    return !!pulseCheckInData?.pulse?.surveys?.requestCheckIn?.response
}

export const dailyCheckInStatusSelector = (
    pulseCheckInData: FetchPulseCheckInMutation
): string => {
    return pulseCheckInData?.pulse?.surveys?.requestCheckIn?.status
}
