import { ApolloError } from '@apollo/client'
import {
    PulseSurveyQuestionDto,
    QuestionDto,
    useFetchPulseCheckInMutation
} from '../../graphql/generated/autogenerated'
import { calculateIsPulseSurveyResponded, getPulseChannel } from './utils'
import { useCheckinDate } from './useCheckinDate'
import { useCallback, useMemo } from 'react'
import {
    dispatchDateSelector,
    hasSurveyRespondedSelector,
    recommendAcuteCareSelector,
    surveyQuestionSelector,
    surveySelector
} from './selectors'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { FeatureFlags } from '../../constants'

export type UseGetPulseCheckinParams =
    | {
          date?: Date
      }
    | undefined

export type FetchPulseCheckInMutationFn = ReturnType<
    typeof useFetchPulseCheckInMutation
>['0']

export type PulseCheckinData = {
    dailyCheckinSurvey: PulseSurveyQuestionDto
    surveyQuestion: QuestionDto
    recommendAcuteCare: boolean
    responded: boolean
    dispatchDate: string
}

export type UseGetPulseCheckinResult = {
    error: ApolloError | Error | undefined | null
    isLoading: boolean
    fetch: () => ReturnType<FetchPulseCheckInMutationFn>
    data: PulseCheckinData
}

export function useGetPulseCheckin({
    date
}: UseGetPulseCheckinParams = {}): UseGetPulseCheckinResult {
    const hideDailyCheckIn = useIsFeatureEnabled(
        FeatureFlags.HideDailyCheckIn,
        false,
        true
    )

    const requestDate = useCheckinDate(date)

    const pulseChannel = getPulseChannel()

    const [fetchPulseCheckIn, { error, loading, data: response }] =
        useFetchPulseCheckInMutation({
            variables: {
                channel: pulseChannel,
                requestDate: requestDate
            }
        })

    const fetch = useCallback(() => {
        return fetchPulseCheckIn()
    }, [fetchPulseCheckIn])

    const data = useMemo(() => {
        const responded: boolean = calculateIsPulseSurveyResponded({
            pulseResponse: response,
            hideDailyCheckIn: hideDailyCheckIn as boolean
        })

        return {
            dailyCheckinSurvey: surveySelector(response),
            surveyQuestion: surveyQuestionSelector(response),
            recommendAcuteCare: recommendAcuteCareSelector(response),
            responded: responded,
            dispatchDate: dispatchDateSelector(response)
        }
    }, [hideDailyCheckIn, response])

    return {
        error,
        isLoading: loading,
        fetch: fetch,
        data: data
    }
}
