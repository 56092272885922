import { type GraphQlMicrostep } from '../graphql/generated/autogenerated'
import {
    type CombinedState,
    type Draft,
    isDraft,
    createSelector,
    createSlice,
    original
} from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'

export const microstepsSliceName = 'microsteps'

export type MicrostepsState = {
    byId: { [id: string]: GraphQlMicrostep }
}

export const initialState: MicrostepsState = {
    byId: {}
}

export type RootStateWithMicrostepsState = CombinedState<{
    [microstepsSliceName]: MicrostepsState
}>

const upsertOneReducer = (
    draft: Draft<MicrostepsState>,
    microstep: GraphQlMicrostep
): MicrostepsState => {
    const { byId } = draft

    const oldMicrostep = byId[microstep.id]

    // If microstep has not changed, do nothing
    if (
        oldMicrostep && isDraft(oldMicrostep)
            ? isEqual(original(oldMicrostep), microstep)
            : isEqual(oldMicrostep, microstep)
    ) {
        return draft
    }

    byId[microstep.id] = microstep

    return draft
}

export const microstepsSlice = createSlice({
    name: microstepsSliceName,
    initialState,
    reducers: {
        upsertManyMicrosteps: (
            state,
            { payload }: { payload: GraphQlMicrostep[] }
        ) => payload.reduce(upsertOneReducer, state),
        upsertOneMicrostep: (
            state,
            { payload }: { payload: GraphQlMicrostep }
        ) => upsertOneReducer(state, payload)
    }
})

export const { upsertOneMicrostep, upsertManyMicrosteps } =
    microstepsSlice.actions

export type MicrostepsSliceActions =
    (typeof microstepsSlice.actions)[keyof typeof microstepsSlice.actions]

export const selectMicrostepsState = (
    rootState: RootStateWithMicrostepsState
) => rootState[microstepsSliceName]

export const selectMicrostepById = createSelector(
    (state: RootStateWithMicrostepsState) => selectMicrostepsState(state),
    (_, id: string) => id,
    (state: MicrostepsState, id: string) => state.byId[id]
)

export const selectMicrostepsByIds = createSelector(
    (state: RootStateWithMicrostepsState) => selectMicrostepsState(state),
    (_, ids: string[]) => ids,
    (state: MicrostepsState, ids: string[]) =>
        ids.map((id) => state.byId[id]).filter(Boolean)
)

export default microstepsSlice.reducer
