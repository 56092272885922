import { Apollo } from '@thriveglobal/thrive-web-core'
import { useMemo } from 'react'
import {
    FetchPersonalizedRecommendedContentQueryResult,
    PersonalizedRecommendedContent as RecommendedTypes,
    useFetchPersonalizedRecommendedContentQuery
} from '../../graphql/generated/autogenerated'
import { ExtractByGQLTypename } from '../../utils/types'

export type PersonalizedRecommendedContent =
    FetchPersonalizedRecommendedContentQueryResult['data']['dailyExperience']['goals']['fetchRecommendedContent'][number]

export type JourneyRecommendedContent = ExtractByGQLTypename<
    PersonalizedRecommendedContent,
    'JourneyDailyStep'
>

export type ArticleRecommendedContent = ExtractByGQLTypename<
    PersonalizedRecommendedContent,
    'ArticleV2'
>

export type UsePersonalizedRecommendedContent =
    | {
          loading: false
          recommendedContent: PersonalizedRecommendedContent[]
          error: null
      }
    | {
          loading: true
          recommendedContent: null
          error: null
      }
    | {
          loading: false
          recommendedContent: null
          error: Apollo.ApolloError
      }

const usePersonalizedRecommendedContent = (limit = 1) => {
    const { data, loading, error } =
        useFetchPersonalizedRecommendedContentQuery({
            variables: {
                limit,
                recommendedTypes: [
                    RecommendedTypes.Article,
                    RecommendedTypes.JourneyDailyStep
                ]
            }
        })
    return useMemo<UsePersonalizedRecommendedContent>(() => {
        if (loading) {
            return {
                loading: true,
                recommendedContent: null,
                error: null
            }
        }

        if (error || !data) {
            return {
                loading: false,
                recommendedContent: null,
                error
            }
        }
        const recommendedContent =
            data?.dailyExperience?.goals?.fetchRecommendedContent ?? []

        return {
            loading: false,
            recommendedContent: recommendedContent,
            error: null
        }
    }, [data, error, loading])
}

export default usePersonalizedRecommendedContent
