import { ComponentProps } from 'react'
import { ThriveApplication } from '@thriveglobal/thrive-web-core'
import { GoalPage, MicrostepPage } from '../graphql/generated/autogenerated'

type ThriveInMemoryCacheConfig = Exclude<
    ComponentProps<typeof ThriveApplication>['apolloClientOptions'],
    undefined
>['cacheOptions']

export const apolloCacheOptions: ThriveInMemoryCacheConfig = {
    typePolicies: {
        Query: {
            merge: true
        },
        ContentExperienceQuery: {
            merge: true
        },
        SocialGroupQuery: {
            merge: true
        },
        ResetQuery: {
            merge: true
        },
        ThriveResetQuery: {
            merge: true
        },
        TodayQuery: {
            merge: true,
            fields: {
                getPaginatedMicrosteps: {
                    keyArgs: [
                        'journeyIds',
                        'onlySubscribed',
                        'onlyCheckedIn',
                        'sortOrder',
                        'pageSize'
                    ],
                    merge(
                        existing: MicrostepPage | undefined,
                        incoming: MicrostepPage
                    ) {
                        const result =
                            existing && incoming?.page !== 1
                                ? {
                                      ...incoming,
                                      items: [
                                          ...existing?.items,
                                          ...incoming?.items
                                      ]
                                  }
                                : incoming

                        return result
                    }
                }
            }
        },
        MicrostepCheckinProgressDate: {
            /* We use date as there is no id for the dates checkin. When a user checks/unchecks a microstep, the query is refetched for
             * the active query. As the date is the key, the checking data for a particular date is shared between queries. The means
             * that when the user changes the date filter, no new queries need to be made.
             */
            keyFields: ['date']
        },
        // NOTE: This cache option is required to prevent having null values during refetching getLatestUserProgressV2
        JourneyQuery: {
            merge: true
        },
        // NOTE: This cache option is required to prevent having null values during refetching getLatestUserProgressV2
        UserJourneyProgressV2: {
            merge: true
        },
        GoalQuery: {
            merge: true,
            fields: {
                fetchCMSGoalsPaginated: {
                    keyArgs: ['journeyIds'],

                    merge(existing: GoalPage | undefined, incoming: GoalPage) {
                        return existing && incoming?.page !== 1
                            ? {
                                  ...incoming,
                                  items: [
                                      ...existing?.items,
                                      ...incoming?.items
                                  ]
                              }
                            : incoming
                    }
                }
            }
        }
    }
}
