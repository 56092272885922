import { Button, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage } from 'react-intl'

export interface QuestListHeaderProps {
    onShowAllQuests: () => void | null
}

const QuestListHeader = ({ onShowAllQuests }: QuestListHeaderProps) => {
    return (
        <Stack
            sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                // NOTE: 44px is the minimum height for the button
                // This is added to prevent jumping when the button is hidden
                minHeight: '44px'
            }}
        >
            <CoreTypography variant="body2" component={'h6'} fontWeight={600}>
                <FormattedMessage
                    defaultMessage="Daily Quests"
                    description="Section title for daily quests"
                />
            </CoreTypography>

            {onShowAllQuests && (
                <Button variant="text" size="small" onClick={onShowAllQuests}>
                    <CoreTypography customVariant="buttonSmall">
                        <FormattedMessage
                            defaultMessage="View all"
                            description="View all button"
                        />
                    </CoreTypography>
                </Button>
            )}
        </Stack>
    )
}

export default QuestListHeader
