import {
    ErrorBoundary,
    type ErrorBoundaryProps
} from '@thriveglobal/thrive-web-errors'

type ErrorBoundaryWrapperProps = Omit<
    ErrorBoundaryProps,
    'packageName' | 'packageVersion' | 'packageOwner'
>

export const ErrorBoundaryWrapper = ({
    children,
    boundaryName,
    boundaryType,
    ...props
}: ErrorBoundaryWrapperProps) => {
    return (
        <ErrorBoundary
            boundaryName={boundaryName}
            boundaryType={boundaryType}
            packageName={process.env.APP_NAME}
            packageOwner={process.env.PROJECT_OWNER}
            packageVersion={process.env.APP_VERSION}
            {...props}
        >
            {children}
        </ErrorBoundary>
    )
}

export default ErrorBoundaryWrapper
