import { useRef } from 'react'
import { format } from '@thriveglobal/thrive-web-core'
import {
    PULSE_CHECKIN_REQUEST_DATE_FORMAT,
    PULSE_CHECKING_REQUEST_DATE_FORMAT_LENGTH
} from './constants'

export function useCheckinDate(date?: Date) {
    const requestDate = useRef(
        format(date ?? new Date(), PULSE_CHECKIN_REQUEST_DATE_FORMAT)
    )

    // NOTE: This is a safeguard to ensure that the date is in the correct format
    const dateWithoutTime = requestDate.current.substring(
        0,
        PULSE_CHECKING_REQUEST_DATE_FORMAT_LENGTH
    )

    return dateWithoutTime
}
