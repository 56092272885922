import {
    FetchPulseCheckInMutation,
    GetPulseSurveyByIdQuery,
    PulseSurveyQuestionDto
} from '../../graphql/generated/autogenerated'

export const surveyByIdSelector = (
    getPulseSurveyByIdQueryResult: GetPulseSurveyByIdQuery
): PulseSurveyQuestionDto => {
    return getPulseSurveyByIdQueryResult?.pulse?.surveys?.getById
}
