import {
    type GameAttributes,
    thriveGameSlice,
    useAppDispatch,
    useAppSelector,
    type UserProgress
} from '@thriveglobal/thrive-web-core'
import { useEffect } from 'react'
import {
    type GetCurrentGameProgressQuery,
    useGetCurrentGameProgressQuery
} from '../../../graphql/generated/autogenerated'
import { selectThriveGameProgress } from '../../../slices/thriveGame'

/**
 * This hook is used to sync the game progress state with the store.
 * It will refetch the data when the refetchToken changes.
 * @param refetchToken - The token to refetch the data.
 * @example
 * ```ts
 * useSyncGameProgressState({ refetchToken: new Date().getTime() })
 * ```
 * @returns The game progress state.
 */
export const useSyncGameProgressState = (
    {
        refetchToken = null
    }: {
        refetchToken: string | number | null
    } = { refetchToken: null }
): UserProgress => {
    const dispatch = useAppDispatch()
    const { data, refetch } = useGetCurrentGameProgressQuery()

    useEffect(
        function updateStoreOnDataChange() {
            if (data) {
                const gameActions = thriveGameSlice.actions

                const gameAttributes = selectGameAttributesFromQuery(data)
                dispatch(gameActions.setGameAttributes(gameAttributes))

                const userProgress = selectUserProgressFromQuery(data)
                dispatch(gameActions.setUserProgress(userProgress))
            }
        },
        [data, dispatch]
    )

    useEffect(
        function refetchOnTokenChange() {
            if (refetchToken) {
                refetch()
            }
        },
        [refetchToken, refetch]
    )

    const gameProgress = useAppSelector(selectThriveGameProgress)

    return gameProgress
}

export function selectGameAttributesFromQuery(
    progressQuery: GetCurrentGameProgressQuery
): GameAttributes | null {
    if (!progressQuery?.game?.getCurrentGameProgress) {
        return null
    }

    return {
        id: progressQuery.game.getCurrentGameProgress.gameId || null
    }
}

export function selectUserProgressFromQuery(
    progressQuery: GetCurrentGameProgressQuery
): UserProgress | null {
    if (!progressQuery?.game?.getCurrentGameProgress) {
        return null
    }

    const {
        currentLevelNumber,
        pointsProgress,
        nextLevelPointsRequired,
        currentLevelPointsRequired
    } = progressQuery.game.getCurrentGameProgress

    return {
        currentLevel: currentLevelNumber,
        currentLevelPointsRequired: currentLevelPointsRequired,
        currentPoints: pointsProgress,
        nextLevelPointsRequired: nextLevelPointsRequired,

        isGameCompleted: nextLevelPointsRequired === null,
        nextLevel:
            nextLevelPointsRequired !== null ? currentLevelNumber + 1 : null
    }
}
