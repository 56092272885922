import { Popover } from '@mui/material'
import { memo, useCallback, useState, type MouseEvent } from 'react'
import { DailyQuestsProgress } from '../DailyQuestsProgress/DailyQuestsProgress'
import GameProgressBadge from '../GameProgressBadge/GameProgressBadge'
import { useSyncGameProgressState } from './useSyncGameProgressState'

const DAILY_QUESTS_PROGRESS_MENU_ID = 'daily-quests-progress-menu'

function ThriveGameProgressNavWidget() {
    const {
        currentPoints,
        currentLevel,
        isGameCompleted,
        nextLevelPointsRequired,
        currentLevelPointsRequired
    } = useSyncGameProgressState()

    const [
        dailyQuestsProgressPopoverAnchorEl,
        setDailyQuestsProgressPopoverAnchorEl
    ] = useState<null | HTMLElement>(null)
    const isDailyQuestsProgressPopoverOpen = Boolean(
        dailyQuestsProgressPopoverAnchorEl
    )

    const handleBadgeClick = useCallback((event: MouseEvent<HTMLElement>) => {
        setDailyQuestsProgressPopoverAnchorEl(event.currentTarget)
    }, [])

    const handleClose = useCallback(() => {
        setDailyQuestsProgressPopoverAnchorEl(null)
    }, [])

    // NOTE: If user reached the last level, we don't have max points for the level,
    // so we use the current points as the indicator total points
    const indicatorPoints = isGameCompleted
        ? currentPoints
        : currentPoints - currentLevelPointsRequired
    const indicatorTotalPoints = isGameCompleted
        ? currentPoints
        : nextLevelPointsRequired - currentLevelPointsRequired

    return (
        <>
            <GameProgressBadge
                points={currentPoints}
                totalPoints={nextLevelPointsRequired}
                isGameFinished={isGameCompleted}
                indicatorPoints={indicatorPoints}
                indicatorTotalPoints={indicatorTotalPoints}
                chipProps={{
                    'aria-controls': isDailyQuestsProgressPopoverOpen
                        ? DAILY_QUESTS_PROGRESS_MENU_ID
                        : undefined,
                    'aria-haspopup': true,
                    'aria-expanded': isDailyQuestsProgressPopoverOpen,
                    onClick: handleBadgeClick
                }}
            />
            <Popover
                open={isDailyQuestsProgressPopoverOpen}
                anchorEl={dailyQuestsProgressPopoverAnchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <DailyQuestsProgress
                    currentPoints={currentPoints}
                    currentLevel={currentLevel}
                    pointsRequiredToLevelUp={nextLevelPointsRequired}
                />
            </Popover>
        </>
    )
}

export default memo(ThriveGameProgressNavWidget)
