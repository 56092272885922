import {
    Channel,
    SupportedRecommendedContentType
} from '../../graphql/generated/autogenerated'

export const PLATFORM_TO_PULSE_CHANNEL_MAP = {
    'hybrid-android': Channel.MobileAndroid,
    'hybrid-ios': Channel.MobileIos,
    msteams: Channel.MsTeams,
    web: Channel.Web
} as const

export const PULSE_CHECKIN_SUBMIT_SUPPORTED_CONTENT_TYPES = [
    SupportedRecommendedContentType.Reset,
    SupportedRecommendedContentType.Article
]

export const PULSE_CHECKIN_REQUEST_DATE_FORMAT = 'YYYY-MM-DD'
export const PULSE_CHECKING_REQUEST_DATE_FORMAT_LENGTH =
    PULSE_CHECKIN_REQUEST_DATE_FORMAT.length

export enum DailyCheckinStatus {
    PREVIOUSLY_SAVED = 'PREVIOUSLY_SAVED'
}

export const MOST_NEGATIVE_DAILY_CHECKIN_OPTION = 1
