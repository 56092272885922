import { useContext } from 'react'
import { PulseContext } from './index'
import { PulseContextType } from './PulseContext'

export const usePulseProvider = (): PulseContextType => {
    const context = useContext(PulseContext)

    if (context === undefined) {
        throw new Error('usePulseProvider must be used within a PulseContext')
    }

    return context
}
