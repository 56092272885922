import { usePulseProvider } from './usePulseProvider'
import { useEffect } from 'react'

export function useDailyCheckin() {
    const {
        fetch: fetchPulseData,
        dailyCheckinSurvey,
        recommendAcuteCare,
        loading,
        responded: dailyCheckinResponded,
        dailyCheckinSubmitted
    } = usePulseProvider()

    useEffect(() => {
        fetchPulseData()
    }, [fetchPulseData])

    return {
        isLoadingDailyCheckin: loading,
        isDailyCheckinSubmitted: dailyCheckinSubmitted,
        isDailyCheckinResponded: dailyCheckinResponded,
        dailyCheckinSurvey,
        recommendAcuteCare
    }
}
