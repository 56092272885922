import { memo, useCallback, useState } from 'react'
import { Button, Stack } from '@mui/material'
import {
    CoreTypography,
    LeafConfirmationDialog
} from '@thriveglobal/thrive-web-leafkit'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useTrackCloseIntentionSettingDialog } from '../../../tracking/intention'

const messages = defineMessages({
    closeDialogTitle: {
        defaultMessage: 'Are you sure?',
        description: 'Title for the close confirmation dialog'
    },
    closeDialogPrimaryButton: {
        defaultMessage: 'Confirm and close',
        description: 'Primary button text for the close confirmation dialog'
    },
    closeDialogSecondaryButton: {
        defaultMessage: 'Nevermind',
        description: 'Secondary button text for the close confirmation dialog'
    }
})

export type IntentionSettingDialogJourneysHeaderProps = {
    onClose: () => void
}

function IntentionSettingDialogJourneysHeader(
    props: IntentionSettingDialogJourneysHeaderProps
) {
    const { onClose } = props

    const { formatMessage } = useIntl()

    const trackCloseDialog = useTrackCloseIntentionSettingDialog()

    const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] =
        useState(false)

    const handleCloseClick = useCallback(() => {
        trackCloseDialog()
        setIsCloseConfirmationOpen(true)
    }, [trackCloseDialog])

    const handleCancelClose = useCallback(() => {
        setIsCloseConfirmationOpen(false)
    }, [])

    const handleConfirmClose = useCallback(() => {
        onClose()
    }, [onClose])

    return (
        <>
            <Stack data-testid="intention-setting-dialog-journeys-header">
                <Button
                    variant={'contained'}
                    color={'secondary'}
                    onClick={handleCloseClick}
                >
                    <CoreTypography customVariant={'buttonNormal'}>
                        <FormattedMessage
                            defaultMessage="Close"
                            description={
                                'Label for the close button in the intention setting dialog'
                            }
                        />
                    </CoreTypography>
                </Button>
            </Stack>
            <LeafConfirmationDialog
                dialogTitle={formatMessage(messages.closeDialogTitle)}
                dialogContent={
                    <Stack alignItems={'center'}>
                        <CoreTypography variant={'body1'}>
                            <FormattedMessage
                                defaultMessage="Are you sure you want to close the intention setting process?"
                                description="Content for the close confirmation dialog"
                            />
                        </CoreTypography>
                    </Stack>
                }
                primaryButtonText={formatMessage(
                    messages.closeDialogPrimaryButton
                )}
                primaryButtonOnClick={handleConfirmClose}
                secondaryButtonText={formatMessage(
                    messages.closeDialogSecondaryButton
                )}
                secondaryButtonOnClick={handleCancelClose}
                open={isCloseConfirmationOpen}
                onClose={handleCancelClose}
            />
        </>
    )
}

export default memo(IntentionSettingDialogJourneysHeader)
