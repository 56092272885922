export enum JOURNEY_ROUTES {
    JOURNEYS = '/journeys',
    SET_INTENTION = '/journeys/set-intention',
    EDIT_INTENTION = '/journeys/edit-intention',
    DAILY_INTELLIGENT_EXPERIENCE = '/journeys/daily',
    JOURNEY_MICROSTEPS = '/journeys/microsteps',
    JOURNEY_MICROSTEPS_BROWSE = '/journeys/microsteps/browse',
    JOURNEY_MICROSTEPS_SAVED = '/journeys/microsteps/saved',
    JOURNEY_MICROSTEPS_COMPLETED = '/journeys/microsteps/completed',
    DCI_CITATIONS = '/journeys/citations'
}

export enum ONBOARDING_ROUTES {
    ONBOARDING = '/onboarding'
}

export enum LEARN_ROUTES {
    ARTICLE_PAGE = '/learn/articles/:articleId',
    ARTICLES = '/learn/articles',
    COURSE_PAGE = '/learn/courses/:courseId',
    COURSE_MODULE = '/learn/courses',
    PODCAST_EPISODE = '/learn/podcasts',
    WEBINAR_PAGE = '/learn/webinars/:webinarId',
    COMPANY_RESOURCES = '/learn/resource',
    LIBRARY = '/learn'
}

export enum CHALLENGE_ROUTES {
    CHALLENGE_PAGE = '/challenges/:challengeId',
    CHALLENGES = '/challenges'
}

export enum TODAY_ROUTES {
    MICROSTEPS = '/today/microsteps/',
    MICROSTEPS_DIALOG = '/today/microsteps/:microstepId'
}

export enum ACHIEVEMENTS_ROUTES {
    ACHIEVEMENTS_GARDEN = '/achievements/garden'
}

export enum RESET_ROUTES {
    RESETS = '/reset',
    RESETS_PLAYER = '/reset/thrive/:id'
}

// Option 1: Create a type union instead
export type APP_ROUTE =
    | JOURNEY_ROUTES
    | ONBOARDING_ROUTES
    | LEARN_ROUTES
    | CHALLENGE_ROUTES
    | TODAY_ROUTES
    | ACHIEVEMENTS_ROUTES
    | RESET_ROUTES

export const ROUTES = {
    ...JOURNEY_ROUTES,
    ...ONBOARDING_ROUTES,
    ...LEARN_ROUTES,
    ...CHALLENGE_ROUTES,
    ...TODAY_ROUTES,
    ...ACHIEVEMENTS_ROUTES,
    ...RESET_ROUTES
} as const
