import { type ComponentType, lazy, memo } from 'react'
import withPulseProvider from '../../context/pulse/withPulseProvider'
import { withOnboardingRedirect } from './withOnboardingRedirect'
import { DciAndAcuteCareProvider } from '../../components/features/DciAndAcuteCare/AcuteCareAndDciContext'

export function withDciAndAcuteCareProvider<Props>(
    Component: ComponentType<Props>
) {
    return memo((props: Props) => {
        return (
            <DciAndAcuteCareProvider>
                <Component {...props} />
            </DciAndAcuteCareProvider>
        )
    })
}

const IntentionPage = lazy(() => import('./IntentionPage'))
export const IntentionPageWithPulseDciAcuteCareAndOnboardingRedirectProvider =
    withPulseProvider(
        withDciAndAcuteCareProvider(withOnboardingRedirect(IntentionPage))
    )
