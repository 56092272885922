import {
    defaultOnboardingCompletedProps,
    defaultPromptSelectedProps
} from './defaultTrackingProps'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { useCallback } from 'react'

export function useTrackTimezoneUpdated() {
    const track = useCallback(() => {
        Avo.promptSelected({
            ...defaultPromptSelectedProps,
            featureType: 'intention_setting',
            activityType: 'timezone_updated',
            isOnboarding: true
        })
    }, [])

    return track
}

export function useTrackLanguageUpdated() {
    const track = useCallback(() => {
        Avo.promptSelected({
            ...defaultPromptSelectedProps,
            featureType: 'intention_setting',
            activityType: 'language_updated',
            isOnboarding: true
        })
    }, [])

    return track
}

export function useTrackSupportButtonClick() {
    const track = useCallback(() => {
        Avo.promptSelected({
            ...defaultPromptSelectedProps,
            featureType: 'intention_setting',
            activityType: 'support_selected',
            isOnboarding: true
        })
    }, [])

    return track
}

export function useTrackOnboardingCompleted() {
    const track = useCallback(() => {
        Avo.onboardingCompleted({
            ...defaultOnboardingCompletedProps,
            featureType: 'intention_setting',
            activityType: 'onboarding_completed',
            isOnboarding: true,
            onboardingAttemptNumber: 1
        })
    }, [])

    return track
}
